<template>
    <v-container fluid class="pl-0 pr-0">
        <v-row justify="center">
            <v-col cols="11" md="6">
                <v-btn block exact outlined @click="$router.back()">
                    <v-icon>mdi-chevron-left</v-icon>
                    Back
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-card outlined>
                    <v-img :src="post.title_image" max-width="100%" contain />
                    <v-card-title class="mb-5 justify-center display-2">{{ post.title }}</v-card-title>
                    <v-card-subtitle class="text-center title font-italic">{{ post.description }}</v-card-subtitle>
                    <v-card-subtitle class="text-center">
                        <v-btn outlined @click="share(fbLink)">
                            <v-icon color="primary">{{ mdiFacebook }}</v-icon>
                            Share
                        </v-btn>
                    </v-card-subtitle>
                    <v-card-text class="body-1 post-text">
                        <component :is="dynamicComponent" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="11" md="6" v-if="!showComments">
                <v-btn outlined block @click="showComments = true">Show Comments</v-btn>     
            </v-col>
            <v-col cols="11" md="6" v-else>
                <Disqus shortname="feeltravelling" :identifier="this.post_id + ''" :url="this.location" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VImg, VCol, VRow, VIcon, VContainer, VCard } from "vuetify/lib";
import { Disqus } from "vue-disqus";
import { mdiFacebook, mdiImageFilterHdr } from "@mdi/js";
import { mapGetters } from "vuex";
import { GET_POST } from "@/store/actions.type";
import store from "../store";

export default {
    name: "post",

    metaInfo() {
        return this.postMeta(this.post, this.location);
    },

    components: {
        VImg, VCol, VRow, VIcon, VContainer, VCard, Disqus
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch(GET_POST, to.params.post_id).then( () => next() );
    },

    props: ["post_id"],

    computed: {
        ...mapGetters(["postMeta", "post"])
    },

    async mounted() {
        this.content = this.post.content;
        this.fbLink = `https://www.facebook.com/sharer/sharer.php?u=${location}&quote=${this.post.title}`;
    },

    data() {
        return {
            mdiFacebook : mdiFacebook,
            mdiImageFilterHdr: mdiImageFilterHdr,
            fbLink: "",
            location: window.location.href,
            showComments: false,
            content: "",
            dynamicComponent: {
                template: `<div></div>`
            }
        };
    },

    methods: {
        share(link) {
            window.open(link, '_blank', "width=500,height=500,menubar=no,toolbar=no")
        },
    },

    watch: {
        content(val) {
            this.dynamicComponent = {
                data() { return { mdiImageFilterHdr: mdiImageFilterHdr } },
                components: { VImg, VCol, VRow, VIcon, VContainer, VCard },
                // Change each img tag to v-img, add placeholder template and a closing tag
                template: `<div> ${val.replace(/<img([\w\W]+?)\/{0,2}>/g, '<v-card outlined><v-img $1><template v-slot:placeholder><v-container class="fill-height"><v-row justify="center"><v-col cols="auto"><v-icon size="128px">{{ mdiImageFilterHdr }}</v-icon></v-col></v-row></v-container></template></v-img></v-card>')} </div>`
            };
        }
    }
};
</script>

<style lang="scss">
</style>
